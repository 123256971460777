import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";

import {IT_MANAGERS_IDS} from "../Components/DevIT/DevITButton";
import DevTicketsList from "../Components/DevIT/DevTicketsList/DevTicketsList";
import {getAllUsers, getCurrentUser} from "../slices/UserSlice";

const ReferencePage = () => {
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const isITManager = IT_MANAGERS_IDS.includes(user?.userId);
  useEffect(() => {
    if (user) dispatch(getAllUsers());
  }, [user]);
  return <Routes>{isITManager && <Route path="/tasksList/*" element={<DevTicketsList />} />}</Routes>;
};

export default ReferencePage;
