import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";

import {selectKit, selectNewKit} from "../../../../slices/SampleFunctionalSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../../Inputs";
import FieldWithSample from "../../../Inputs/FieldWithSample";
import {TYPE_FORMS} from "../../SamplesList";

const SetModalPart = ({utilits, complectChecked, typeForm}) => {
  const {t} = useTranslation();

  const {values, resetForm, setValues, setFieldValue} = useFormikContext();
  const {setDescArea, kitSku} = values;

  const kit = useSelector(selectKit);
  const newKit = useSelector(selectNewKit);

  const [formChanges, setFormChanges] = useState(false);

  useEffect(() => {
    const isChanged = !isEqual(
      {name: values.setDescArea, skuNumber: values.kitSku},
      {name: kit?.name, skuNumber: kit?.skuNumber},
    );

    setFormChanges(isChanged);
  }, [values]);

  return (
    <div className="part_width flex_form jcsb">
      <div className="fdr">
        <FieldWithSample label={t("sett")} name="setDesc" checked={complectChecked} style={{width: "20%"}} />
        <TextInput
          label="kitSKU № *"
          name="kitSku"
          labelStyles={{width: "4em"}}
          disabled={typeForm === TYPE_FORMS.UPDATE_INSTANCE}
          generalClassName="leftLebel"
        />
      </div>
      <div>
        <TextAreaInput
          label=""
          name="setDescArea"
          width="101%"
          placeholder={t("samp nom") + "*" + ": " + t("1000")}
          disabled={typeForm === TYPE_FORMS.UPDATE_INSTANCE}
        />
        <div className="fdr g1 jcfe mt">
          <FunctionButton
            name={utilits[0].props.name}
            onClick={() => {
              utilits[0].props.onClick(
                {
                  skuNumber: kitSku,
                  name: setDescArea,
                  typeForm,
                },
                setFieldValue,
                setValues,
              );
            }}
            disabled={
              typeForm === "CREATE_KIT"
                ? !setDescArea || newKit
                : typeForm === TYPE_FORMS.UPDATE_INSTANCE || !formChanges || !kitSku
            }
          />
          <FunctionButton
            name={utilits[2].props.name}
            onClick={() => {
              utilits[2].props.onClick(
                {
                  ...kit,
                  name: kit?.name,
                  skuNumber: kit?.skuNumber,
                },
                setValues,
                resetForm,
              );
            }}
            disabled={typeForm === TYPE_FORMS.UPDATE_INSTANCE || kitSku === "" || !formChanges || newKit}
          />
        </div>
      </div>
    </div>
  );
};
export default SetModalPart;
