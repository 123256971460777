import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import cat from "../../customEvents/valentines/cat_paw.png";
import car from "../../customEvents/valentines/hearts_car.png";
import {lastUpdateDate} from "../../gitIgnore/updateDate";
import {login} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {TextInput} from "../Inputs";
import PasswordInput from "../Inputs/PasswordInput/PasswordInput";

import "./authForm.scss";

const LoginForm = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {token, user} = useSelector(state => state.user);

  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    return {
      email: "",
      password: "",
    };
  }, []);

  useEffect(() => {
    if (token && user) navigate("/ba3a/warehouse/samples");
  }, [token]);

  return (
    <>
      <div style={{width: "100%", display: "flex", justifyContent: "center", position: "fixed", bottom: "0"}}>
        <img src={cat} style={{width: "400px", height: "auto", zIndex: "1", opacity: "1"}} />
      </div>
      <div className="login-form__container">
        <div className="login_form">
          <label className="label login_form_label">{t("Auth")}</label>
          <img
            src={car}
            style={{width: "200px", height: "auto", position: "absolute", top: "-160px", right: "10px", zIndex: "1"}}
          />

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              email: Yup.string().required(t("Enter Login")),
              password: Yup.string().required(t("Enter Password")),
            })}
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={values => {
              dispatch(login(values));
            }}
          >
            {({values}) => (
              <Form className="project_form">
                <TextInput label={t("Login")} id="email" name="email" type="text" />
                <PasswordInput label={t("Password")} id="password" name="password" />
                <p>
                  {t("Update")} {lastUpdateDate}{" "}
                </p>
                <FunctionButton type="submit" name={t("Sign in")} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
