import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import ModalForm from "../Modal/FormModal";

import TranslationButton from "./Translation/TranslationButton";

const useSettingsModal = () => {
  const [showModal, setShowModal] = useState(false);
  const {t} = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("appsettings")) {
      setShowModal(true);
    }
  }, [location]);

  const onClose = () => {
    navigate(-1);
  };

  const componentsSwitch = () => {
    const finalRoute = location.pathname.split("/").at(-1);
    switch (finalRoute) {
      case "translate":
        return {
          label: t("Appeal to dev IT"),
          Component: TranslationButton,
        };
    }
  };

  const renderModal = () => {
    const {Component, label} = componentsSwitch();
    return (
      <ModalForm
        closable={true}
        active={showModal}
        Component={Component}
        setModalActive={setShowModal}
        label={label}
        onModalClose={onClose}
      />
    );
  };

  return {renderModal, showModal};
};

export default useSettingsModal;
