import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";

import {ReactComponent as AlarmClockIcon} from "../../../../images/icons/alarm.svg";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {DateInput, TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import FilterButton from "../FilterButton";

import useSampleFilter from "./useSampleFilter";

const SampleFilter = ({
  storageKey,
  onFilterAdditional = () => {},
  onFilterDelAdditional = () => {},
  isFree = false,
  children,
}) => {
  const {t} = useTranslation();

  const {
    initialValues,
    factoriesOptions,
    statusOptions,
    officesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    categoriesOptions,
    texturesOptions,
    brandManagerOptions,
    accManagerOptions,
    areFiltersDefined,
    onFilter,
    onDelFilters,
    onGroupSelect,
    onMaterialSelect,
  } = useSampleFilter({storageKey, isFree});

  const filterOnEnter = (e, values) => {
    if (e.code === "Enter") {
      onFilter(values);
      onFilterAdditional(values);
    }
  };

  return (
    <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} enableReinitialize>
      {({values}) => (
        <Form className="" style={{width: "100%"}}>
          <div
            style={{
              gap: "1em",
              alignItems: "flex-start",
              width: "100%",
            }}
            className="fc jcfs autolabel nowrap"
          >
            <div className="flex_container aist gap5" style={{flexDirection: "column"}}>
              <FilterButton
                onFilter={() => {
                  onFilter(values);
                  onFilterAdditional(values);
                }}
                delFilter={() => {
                  onDelFilters(values);
                  onFilterDelAdditional(values);
                }}
                questionTitle={t("Filter")}
                areFiltersDefined={areFiltersDefined}
              />
              <ImageButton disabled>
                <AlarmClockIcon width={40} fill="red" />
              </ImageButton>
              {children}
            </div>
            <div className="samp_filter_block">
              <div className="samp_filter_block_left">
                <Select
                  label={t("Factory")}
                  name="factoryId"
                  options={factoriesOptions}
                  multi={true}
                  width="100%"
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  minFilterLength={1}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <TextInput
                  label="kitSKU №"
                  name="kitSkuNumber"
                  width="100%"
                  generalClassName="samp_filter_item"
                  labelStyles={{width: "10em"}}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <TextInput
                  label="SKU №"
                  name="skuNumber"
                  width="100%"
                  generalClassName="samp_filter_item"
                  labelStyles={{width: "10em"}}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("Status*")}
                  name="statusId"
                  options={statusOptions}
                  multi={true}
                  width="100%"
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("Offic")}
                  name="officeId"
                  options={officesOptions}
                  multi={true}
                  width="100%"
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("Smp g")}
                  name="groupId"
                  options={groupsOptions}
                  width="100%"
                  multi={true}
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  makeEffect={option => onGroupSelect(option.value)}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("sgr")}
                  name="subgroupId"
                  options={subGroupsOptions}
                  width="100%"
                  multi={true}
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  disabled={!values.groupId}
                  // disabled={calcSubGroupDisabled(values)}
                  question={t("subgroup filter tooltip")}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("material")}
                  name="materialId"
                  options={materialsOptions}
                  width="100%"
                  multi={true}
                  minFilterLength={1}
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  makeEffect={option => onMaterialSelect(option.value)}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("categ")}
                  name="categoryId"
                  options={categoriesOptions}
                  width="100%"
                  multi={true}
                  minFilterLength={1}
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  disabled={!values.materialId}
                  // disabled={calcIsCategoryDisabled(values)}
                  question={t("category filter tooltip")}
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("texture")}
                  name="textureId"
                  options={texturesOptions}
                  width="100%"
                  multi={true}
                  generalClassName="samp_filter_item"
                  labelWidth="10em"
                  onKeyDown={e => filterOnEnter(e, values)}
                />

                {/* <div className="samp_filter_area_row"> */}
                <TextAreaInput
                  label=""
                  name="collection"
                  placeholder={t("search") + " - " + t("col")}
                  width="19em"
                  height="4em"
                  className="samp_filter_item_two"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <TextAreaInput
                  label=""
                  name="color"
                  placeholder={t("search") + " - " + t("Color")}
                  width="19em"
                  height="4em"
                  className="samp_filter_item_two"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <TextAreaInput
                  label=""
                  name="name"
                  placeholder={t("search") + " - " + t("samp nom")}
                  width="19em"
                  height="4em"
                  className="samp_filter_item_two"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("BM")}
                  name="brandManagerId"
                  options={brandManagerOptions}
                  width="100%"
                  multi={true}
                  generalClassName="samp_filter_item_two"
                  labelWidth="10em"
                  onKeyDown={e => filterOnEnter(e, values)}
                />
                <Select
                  label={t("acc m")}
                  name="managerId"
                  options={accManagerOptions}
                  width="100%"
                  multi={true}
                  generalClassName="samp_filter_item_two"
                  labelWidth="10em"
                  onKeyDown={e => filterOnEnter(e, values)}
                />

                {/* </div> */}
              </div>

              {/* <Select
            label={t("Country")}
            name="country"
            options={[]}
            width="100%"
            multi={true}
            generalClassName="samp_filter_item"
            labelWidth="14em"
          /> */}
              {/* <div className="samp_filter_item"></div> */}
              {/* </div>
            <div className="period-wrapper samp_filter_item"> */}
              <div className="period_nom_wrapper">
                <div className="period_nom">{t("acc per")}</div>
                <div className="request-date-wrapper">
                  <DateInput label={t("From")} name="dateStart" />
                  <DateInput label={t("To")} name="dateEnd" />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SampleFilter.propTypes = {
  children: PropTypes.node,
};

export default SampleFilter;
