import {useDispatch, useSelector} from "react-redux";

import {getCurrentUser} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";

import {selectAllDevITTickets, setDevItModalFlag} from "./appealModal/DevITSlice";

export const IT_MANAGERS_IDS = [32, 1, 3, 5, 13];

const DevITButton = () => {
  const user = useSelector(getCurrentUser);
  const devITTickets = useSelector(selectAllDevITTickets);
  const areNewTickets = devITTickets?.find(ticket => ticket.statusId === 1);
  const isITManager = IT_MANAGERS_IDS.includes(user?.userId);

  const dispatch = useDispatch();

  const showDevItModal = () => {
    dispatch(setDevItModalFlag(true));
  };

  return (
    <>
      <FunctionButton
        name="DevIT"
        onClick={showDevItModal}
        className={`nav-item ${areNewTickets && isITManager ? "accented" : ""}`}
        style={{marginLeft: "4rem", padding: "0.3rem 0.7rem", backgroundColor: "aliceblue"}}
        autoWidth
      />
    </>
  );
};

export default DevITButton;
