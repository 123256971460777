import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";

import useSelectOptions from "../../../../hook/useSelectOptions";
import {
  deselectSample,
  deselectSampleImages,
  selectNewKit,
  selectSample,
} from "../../../../slices/SampleFunctionalSlice";
import {fetchAllCategories, fetchAllSubGroups} from "../../../../slices/SampleSlice/operations";
import {
  clearCurrentTableSample,
  setCurrentTableSample,
  setCurrentTableSampleImages,
  setNoNewSample,
} from "../../../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../../../slices/SampleSlice/selectors";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../utilities/ToolTip";
import {TextAreaInput, TextInput} from "../../../Inputs";
import FieldWithSample from "../../../Inputs/FieldWithSample";
import {Select} from "../../../Inputs/SelectField";
import {TYPE_FORMS} from "../../SamplesList";

const InstanceModalPart = ({utilits, complectChecked, setCurrentKitSample, typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [resentlyMadeSample, setResentlyMadeSample] = useState(null);

  const {values, resetForm, setValues} = useFormikContext();
  const {materialId, groupId, subgroupId, categoryId, textureId, collection, color, name, factoryId, skuNumber} =
    values;

  const areAllRequiredFieldsFilled = skuNumber && groupId && materialId && factoryId && name;

  const chosenSample = useSelector(state => state.samples.modalData[typeForm]?.sample);
  const existingSample = useSelector(selectSample);
  const chosenTableSample = useSelector(state => state.samples.modalData[typeForm]?.sample);
  const currentKitId = useSelector(selectCurrentKitId);

  const sample = typeForm === TYPE_FORMS.UPDATE_INSTANCE ? existingSample ?? chosenTableSample : chosenSample;
  // const newSample = useSelector(selectNewSample);
  // const sample = typeForm === TYPE_FORMS.CREATE_KIT ? newSample : existingSample;

  // const chosenKit = useSelector(selectKit);
  const sampleKit = sample?.kit;
  const newKit = useSelector(selectNewKit);
  // const kit = typeForm !== TYPE_FORMS.CREATE_KIT && typeForm !== TYPE_FORMS.CREATE_INSTANCE ? sampleKit : null;
  const kit = typeForm === TYPE_FORMS.CREATE_KIT ? newKit : typeForm === TYPE_FORMS.CREATE_INSTANCE ? null : sampleKit;
  // const newKit = useSelector(selectNewKit);
  const isNewKitAdded = !!useSelector(state => state.samples.modalData[typeForm].isNewKitAdded);
  const isNewSampleAdded = !!useSelector(state => state.samples.modalData[typeForm].isNewSampleAdded);

  const {groupsOptions, subGroupsOptions, materialsOptions, texturesOptions, categoriesOptions, factoriesOptions} =
    useSelectOptions();

  useEffect(() => {
    if (materialId) {
      dispatch(fetchAllCategories(materialId));
    }
  }, [materialId]);

  useEffect(() => {
    if (groupId) {
      dispatch(fetchAllSubGroups(groupId));
    }
  }, [groupId]);

  const [formChanges, setFormChanges] = useState(false);
  // const currUser = useSelector(getCurrentUser);

  useEffect(() => {
    // const isChanged =
    //   (sample?.sampleId && !isEqual(existingSample, sample)) || (sample?.sampleId && !isEqual(values, sample))||
    //   !isEqual(
    //     {
    //       factoryId: values?.factoryId || "",
    //       skuNumber: values?.skuNumber || "",
    //       groupId: values?.groupId || "",
    //       subgroupId: values?.subgroupId || "",
    //       materialId: values?.materialId || "",
    //       categoryId: values?.categoryId || "",
    //       textureId: values?.textureId || "",
    //       collection: values?.collection || "",
    //       color: values?.color || "",
    //       name: values?.name || "",
    //       state: values?.state || "",
    //     },
    //     {
    //       factoryId: existingSample?.factoryId || "",
    //       skuNumber: existingSample?.skuNumber || "",
    //       groupId: existingSample?.groupId || "",
    //       subgroupId: existingSample?.subgroupId || "",
    //       materialId: existingSample?.materialId || "",
    //       categoryId: existingSample?.categoryId || "",
    //       textureId: existingSample?.textureId || "",
    //       collection: existingSample?.collection || "",
    //       color: existingSample?.color || "",
    //       name: existingSample?.name || "",
    //       state: existingSample?.state || "",
    //     },
    //   );

    const isChanged =
      sample && Object.keys(sample).length === 1
        ? false
        : !isEqual(
            {
              factoryId: values?.factoryId || "",
              skuNumber: values?.skuNumber || "",
              groupId: values?.groupId || "",
              subgroupId: values?.subgroupId || "",
              materialId: values?.materialId || "",
              categoryId: values?.categoryId || "",
              textureId: values?.textureId || "",
              collection: values?.collection || "",
              color: values?.color || "",
              name: values?.name || "",
              state: values?.state || "",
            },
            {
              factoryId: sample?.factoryId || "",
              skuNumber: sample?.skuNumber || "",
              groupId: sample?.groupId || "",
              subgroupId: sample?.subgroupId || "",
              materialId: sample?.materialId || "",
              categoryId: sample?.categoryId || "",
              textureId: sample?.textureId || "",
              collection: sample?.collection || "",
              color: sample?.color || "",
              name: sample?.name || "",
              state: sample?.state || "",
            },
          );

    setFormChanges(isChanged);
  }, [values, sample, existingSample]);

  const onAddMore = () => {
    // dispatch(deselectSample());
    // dispatch(deselectSampleImages());
    dispatch(clearCurrentTableSample({typeForm}));
    dispatch(setNoNewSample({typeForm}));

    setCurrentKitSample(null);
    setTimeout(() => {
      setValues({
        // brandManager: currUser?.lastName + " " + currUser?.firstName,
        factoryId: kit?.samples ? kit?.samples[kit?.samples?.length - 1]?.factoryId ?? "" : "",
        kitSku: kit?.skuNumber,
        setDescArea: kit?.name,
        skuNumber: "",
        groupId: "",
        subgroupId: "",
        materialId: "",
        categoryId: "",
        textureId: "",
        collection: "",
        color: "",
        name: "",
        state: "",
      });
    }, []);
  };

  const onDublicateKitInstance = () => {
    // setCurrentKitSample(null);
    setTimeout(() => {
      setValues({
        // brandManager: currUser?.lastName + " " + currUser?.firstName,
        factoryId: sample?.factoryId || "",
        kitSku: kit.skuNumber,
        setDescArea: kit.name,
        skuNumber: sample?.skuNumber || "",
        groupId: sample?.groupId || "",
        subgroupId: sample?.subgroupId || "",
        materialId: sample?.materialId || "",
        categoryId: sample?.categoryId || "",
        textureId: sample?.textureId || "",
        collection: sample?.collection || "",
        color: sample?.color || "",
        name: sample?.name || "",
        state: sample?.state || "",
      });
    }, []);

    // dispatch(deselectSample());
    // dispatch(deselectSampleImages());
    dispatch(setCurrentTableSampleImages({typeForm, sample: null}));
    dispatch(setCurrentTableSample({typeForm, sample: null}));
    dispatch(setNoNewSample({typeForm}));
  };

  const specValues = useMemo(() => {
    return {
      factoryId,
      skuNumber,
      groupId,
      subgroupId,
      materialId,
      categoryId,
      textureId,
      collection,
      color,
      name,
    };
  }, [values]);

  useEffect(() => {
    if (resentlyMadeSample) {
      setValues({
        factoryId: resentlyMadeSample?.factoryId || "",
        // kitSku: kit.skuNumber,
        // setDescArea: kit.name,
        skuNumber: "",
        groupId: resentlyMadeSample?.groupId || "",
        subgroupId: resentlyMadeSample?.subgroupId || "",
        materialId: resentlyMadeSample?.materialId || "",
        categoryId: resentlyMadeSample?.categoryId || "",
        textureId: resentlyMadeSample?.textureId || "",
        collection: resentlyMadeSample?.collection || "",
        color: "",
        name: resentlyMadeSample?.name || "",
        state: "",
      });
    }
  }, [resentlyMadeSample]);

  const onSimilarSampleAdd = () => {
    setResentlyMadeSample(sample);
    // const resSampl = sample;

    dispatch(deselectSampleImages());
    dispatch(deselectSample());
  };

  const disableFields =
    (typeForm === "UPDATE_KIT" && !!chosenTableSample) ||
    (typeForm === "CREATE_KIT" && (!!isNewSampleAdded || !isNewKitAdded)) ||
    (typeForm === "CREATE_INSTANCE" && !!isNewSampleAdded);

  return (
    <div className="part_width">
      <FieldWithSample
        label={t("inst")}
        name="instance"
        checked={currentKitId || !!kit || isNewKitAdded || typeForm === "UPDATE_KIT"}
        style={{width: "20%"}}
      />

      <div className="lower_selects">
        <TextInput
          label="SKU №"
          name="skuNumber"
          generalClassName="lower_selects_item leftLebel"
          labelStyles={{width: "4em"}}
          disabled={disableFields}
        />
        <Select
          label={t("Factory") + "*"}
          name="factoryId"
          options={factoriesOptions}
          minFilterLength={1}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("Smp g") + "*"}
          name="groupId"
          options={groupsOptions}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("sgr")}
          name="subgroupId"
          options={subGroupsOptions}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("material") + "*"}
          name="materialId"
          options={materialsOptions}
          minFilterLength={1}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("categ")}
          name="categoryId"
          options={categoriesOptions}
          minFilterLength={1}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("texture")}
          name="textureId"
          options={texturesOptions}
          // selectOnly
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
      </div>
      <div className="fdr jcsb" style={{width: "100.5%"}}>
        <TextAreaInput
          label=""
          name="collection"
          placeholder={t("col") + ": "}
          width="50%"
          height="3em"
          className=""
          disabled={disableFields}
        />
        <TextAreaInput
          label=""
          name="color"
          placeholder={t("Color") + ": "}
          width="50%"
          height="3em"
          className=""
          disabled={disableFields}
        />
      </div>
      <TextAreaInput
        label=""
        name="name"
        placeholder={t("samp nom") + "*" + ": " + t("1000")}
        width="101%"
        height="3em"
        className=""
        disabled={disableFields}
      />
      <TextAreaInput
        label=""
        name="state"
        placeholder={t("samp note") + ": " + t("1000")}
        width="101%"
        height="3em"
        className=""
        disabled={disableFields}
      />

      <div className="fdr jcsb mt">
        {complectChecked && (
          <>
            <FunctionButton
              name="add more"
              onClick={onAddMore}
              disabled={!isNewSampleAdded && !chosenTableSample}
              width={"8em"}
            />
            <FunctionButton
              name="dubl"
              onClick={onDublicateKitInstance}
              disabled={!isNewSampleAdded && !chosenTableSample}
              width={"8em"}
            />
          </>
        )}

        <div className="fdr g1">
          <ToolTip title={"Required fields InstanceModalPart"} placement="bottom">
            <FunctionButton
              name={utilits[1].props?.name ?? "Save"}
              type="submit"
              disabled={
                !areAllRequiredFieldsFilled ||
                disableFields ||
                !formChanges ||
                (typeForm === "CREATE_KIT" && isNewSampleAdded) ||
                (typeForm === "CREATE_INSTANCE" && isNewSampleAdded)
              }
              width={"8em"}
            />
          </ToolTip>
          {typeForm === "CREATE_INSTANCE" && !!sample && (
            <FunctionButton name={"Add similar"} type="button" onClick={onSimilarSampleAdd} autoWidth />
          )}
        </div>
        <FunctionButton
          name={utilits[3].props?.name ?? "Cancel "}
          onClick={() => {
            utilits[3].props.onClick(
              {
                ...sample,

                kitSku: sample?.kit?.skuNumber || "",
                setDescArea: sample?.kit?.name || "",
              },
              setValues,
              resetForm,
            );
          }}
          disabled={
            disableFields ||
            Object.values(specValues).every(item => item === "") ||
            !formChanges ||
            (typeForm !== "UPDATE_INSTANCE" && sample)
          }
          width={"8em"}
        />
      </div>
    </div>
  );
};

export default InstanceModalPart;
