import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {withFormik} from "formik";
import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import {DateInput, Select, TextInput} from "../../Inputs";
import {DEV_TICKETS_LIST_WINDOW_NAME} from "../DevTicketsList/DevTicketsList";

import DevITBody from "./DevITBody";
import {clearCurrentTicket, fetchAllTickets, fetchDevITFilters, selectDevITFilters} from "./DevITSlice";

import "../src/DevIt.scss";

const DevIT = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  useEffect(() => {
    dispatch(clearCurrentTicket());

    return () => {
      dispatch(clearCurrentTicket());
      if (location.pathname === "/ba3a/references/tasksList") {
        const filters = JSON.parse(sessionStorage.getItem(DEV_TICKETS_LIST_WINDOW_NAME));
        dispatch(fetchAllTickets({params: filters}));
        dispatch(fetchDevITFilters({params: filters}));
      }
    };
  }, []);

  const fetchedFilters = useSelector(selectDevITFilters);

  const typesFiltersOptions =
    fetchedFilters?.types?.map(({typeId, nameEng, nameUkr}) => ({
      value: typeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];

  const onFilterChange = filters => {
    dispatch(clearCurrentTicket());

    if (!filters) {
      dispatch(fetchAllTickets({}));
      return;
    }

    dispatch(fetchAllTickets({params: filters}));
  };

  return (
    <div className="fcCol pt5 h100 fGrow maxH100">
      <SessionFiltersContainer window="DevIT" onFilterChange={onFilterChange}>
        <Filters>
          <div className="row w100 gap h100 aic">
            <Select label={t("Appeal type")} options={typesFiltersOptions} name="typeId" />

            <TextInput label={t("Appeal text")} name="text" generalClassName="fGrow" autolabel />
            <DateInput label={t("Period From")} name="dateFrom" />
            <DateInput label={t("To")} name="dateTo" />
          </div>
        </Filters>
        <Divider noMargin />
        <DevITBody />
      </SessionFiltersContainer>
    </div>
  );
};

export default withFormik({enableReinitialize: true})(DevIT);
