import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import * as Yup from "yup";

import {selectNewSample} from "../../../../slices/SampleFunctionalSlice";
import {getCurrentUser} from "../../../../slices/UserSlice";
import {TYPE_FORMS} from "../../../SamplesList/SamplesList";
import {SAMPLE_TABS_INITIAL_VALUES} from "../constants";

const useCreateSampleTab = () => {
  const typeForm = TYPE_FORMS.CREATE_SAMPLE;

  const {t} = useTranslation();

  const newSample = useSelector(selectNewSample);
  const sampleImgs = useSelector(state => state.samples.modalData[typeForm]?.sampleImages) ?? [];
  const savedCreateSample = useSelector(state => state.samples.modalData[typeForm]);
  const currentUser = useSelector(getCurrentUser);

  const validationSchema = Yup.object().shape({
    factoryId: Yup.number(t("Оберіть фабрику зі списку")).required(t("Оберіть фабрику зі списку")).nullable(),
    groupId: Yup.number().required(t("select gr")).nullable(),
    materialId: Yup.number().required(t("select mat")).nullable(),
    name: Yup.string().required(t("enter name")),
    skuNumber: Yup.string()
      .matches(/^[a-zA-Z0-9-]*$/, t("skuNumber valid1"))
      .matches(/^(?!.*--).*$/, t("skuNumber valid2"))
      .matches(/^(?!.*-$).*$/, t("skuNumber valid3")),
  });

  const initialValues = {
    ...SAMPLE_TABS_INITIAL_VALUES,
    ...savedCreateSample,
    ...(newSample || {}),
    brandManager: `${currentUser?.lastName ?? ""} ${currentUser?.firstName ?? ""}`,
  };

  return {validationSchema, newSample, sampleImgs, initialValues, typeForm};
};

export default useCreateSampleTab;
