import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";

import useSettingsModal from "../Components/ba3aSettings/useSettingsModal";
import DevIT from "../Components/DevIT/appealModal/DevIT";
import {fetchAllTickets, selectDevItModalFlag, setDevItModalFlag} from "../Components/DevIT/appealModal/DevITSlice";
import ModalForm from "../Components/Modal/FormModal";
import Spinner from "../Components/spinner/Spinner";
import {getCurrentUser, getUser} from "../slices/UserSlice";

const IndexPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const token = useSelector(state => state.user.token);
  const isDevItModalOpen = useSelector(selectDevItModalFlag);
  // const location = useLocation();

  const [loading, setLoading] = useState(true);
  const {renderModal, showModal} = useSettingsModal();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getUser()).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
        dispatch(fetchAllTickets({}));
      }
    });
  }, [token]);

  const setDevItModalOpen = isOpen => {
    dispatch(setDevItModalFlag(isOpen));
  };

  if (loading) return <Spinner />;

  if (!user?.role || user?.role?.roleId === 2) {
    return <Navigate to="/accessdenied" replace />;
  }

  return (
    <>
      <Outlet />
      {showModal && renderModal()}
      <ModalForm
        closable={true}
        active={isDevItModalOpen}
        Component={DevIT}
        setModalActive={setDevItModalOpen}
        label={t("Appeal to dev IT")}
        style={{width: "90%", height: "85%"}}
        className="largeModal"
      />
    </>
  );
};

export default IndexPage;
