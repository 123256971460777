import moment from "moment";

import {STATUS_COLORS} from "./colorConstants";

export default function calcStatusColor({startDate, finalDate}) {
  if (startDate && finalDate) {
    const startDateMs = moment(startDate, "DD.MM.YYYY").valueOf() || moment(startDate).valueOf();
    const finalDateMs = moment(finalDate, "DD.MM.YYYY").valueOf() || moment(finalDate).valueOf();
    const currentDate = moment().format("DD.MM.YYYY");
    const currentDateMs = moment(currentDate, "DD.MM.YYYY").valueOf();

    const totalPeriod = finalDateMs - startDateMs;

    if (startDateMs === finalDateMs && finalDateMs === currentDateMs) return STATUS_COLORS.YELLOW;
    if (startDateMs > finalDateMs) return STATUS_COLORS.RED;
    if (currentDateMs < startDateMs) return STATUS_COLORS.GREEN;
    if (currentDateMs > finalDateMs) {
      return (currentDateMs - finalDateMs) / totalPeriod >= 0.2 ? STATUS_COLORS.RED : STATUS_COLORS.YELLOW;
    }
    if (currentDateMs <= finalDateMs) {
      return (currentDateMs - startDateMs) / totalPeriod >= 0.8 ? STATUS_COLORS.YELLOW : STATUS_COLORS.GREEN;
    }
  }
  return STATUS_COLORS.BLUE;
}
