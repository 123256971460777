import {useEffect, useMemo, useRef, useState} from "react";

import {checkEmptyFilterFields} from "../utilities/checkEmptyFilterFields";

const useSessionFilters = filtersKey => {
  // const [filters, setFiltrs] = useState(getFilters());

  // useEffect(() => {
  //   setFiltrs(getFilters());
  // }, []);

  const getFilters = () => {
    let parsedValue = null;
    try {
      parsedValue = JSON.parse(sessionStorage.getItem(filtersKey));
    } catch (error) {
      console.error(error);
    }
    return parsedValue;
  };
  const filters = useRef(getFilters());
  const setFilters = value => sessionStorage.setItem(filtersKey, JSON.stringify(checkEmptyFilterFields(value)));
  const clearFilters = () => sessionStorage.removeItem(filtersKey);

  const initialFilters = useMemo(() => getFilters() || null, []);

  return {filters: filters.current, setFilters, clearFilters, initialFilters};
};

export default useSessionFilters;
