import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import * as Yup from "yup";

import {selectSample} from "../../../../slices/SampleFunctionalSlice";
import {formSampleValues} from "../../../KitsList/constants";
import {TYPE_FORMS} from "../../../SamplesList/SamplesList";
import {SAMPLE_TABS_INITIAL_VALUES} from "../constants";

const useEditSampleTab = () => {
  const typeForm = TYPE_FORMS.EDIT_SAMPLE;

  const {t} = useTranslation();

  const sample = useSelector(selectSample);
  const savedEditTabSample = useSelector(state => state.samples.modalData[TYPE_FORMS.EDIT_SAMPLE]);

  const shownSample = savedEditTabSample?.sampleId ? savedEditTabSample : sample;

  const {brandManager, sampleQrCodeUrl, sampleImages} = shownSample ?? {};

  const validationSchema = Yup.object().shape({
    factoryId: Yup.number(t("Оберіть фабрику зі списку")).required(t("Оберіть фабрику зі списку")).nullable(),
    groupId: Yup.number().required(t("select gr")).nullable(),
    materialId: Yup.number().required(t("select mat")).nullable(),
    name: Yup.string().required(t("enter name")),
    skuNumber: Yup.string()
      .matches(/^[a-zA-Z0-9-]*$/, t("skuNumber valid1"))
      .matches(/^(?!.*--).*$/, t("skuNumber valid2"))
      .matches(/^(?!.*-$).*$/, t("skuNumber valid3")),
  });

  const initialValues = {
    ...SAMPLE_TABS_INITIAL_VALUES,
    ...formSampleValues(shownSample),
    brandManager:
      typeof brandManager === "string"
        ? brandManager
        : `${brandManager?.lastName ?? ""} ${brandManager?.firstName ?? ""}`,
    sampleQrCodeUrl: sampleQrCodeUrl ?? "",
    sampleImages: sampleImages ?? [],
  };

  return {
    typeForm,
    validationSchema,
    initialValues,
    sample,
  };
};

export default useEditSampleTab;
