import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import DeleteIcon from "../../../images/icons/cross.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
const ChosenFiltersLine = ({filtersRow, onFilter}) => {
  const {setValues} = useFormikContext();
  const {t} = useTranslation();

  const deleteFilter = filter => {
    setValues(prev => {
      let newValues = {...prev, [filter?.name]: ""};
      if (prev?.[filter?.name + "Id"] && prev?.[filter?.name + "Id"] !== "") newValues[filter?.name + "Id"] = null;
      onFilter(newValues);
      return newValues;
    });
  };
  return (
    <div className="row mt0_5 jcfs" style={{padding: "7px"}}>
      {filtersRow?.map(filter => {
        return (
          <div key={filter?.label} className="row borderMiddle gap5 paddingMiddle">
            <div style={{fontSize: "0.9rem"}}>{t(filter?.label) + " : " + filter?.value}</div>
            <ImageButton
              src={DeleteIcon}
              onClick={() => {
                deleteFilter(filter);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChosenFiltersLine;
