import {t} from "i18next";

import SaveIcon from "../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import NewPdfViewer from "../../conteiners/DocumentView/NewPdfViewer";
import ReactTable from "../../conteiners/ReactTable";
import {DateInput, TextAreaInput} from "../../Inputs";
import CheckBox from "../../Inputs/CheckBox";
import PdfFilePreview from "../../Inputs/fileSelect copy/preview/PdfPreview";
import {Select} from "../../Inputs/SelectField";
import ReadyIcon from "../src/ready.svg";

import useDevTicketsList from "./useDevTicketsList";

const DevTicketsListBody = () => {
  const {
    fileArray,
    ticketsTableColumns,
    ticketsTableData,
    onTakeIntoWorkBtnClick,
    onSaveBtnClick,
    onReadyBtnClick,
    iTManagersOptions,
    managerITTableColumns,
    managerITTableData,
    onTicketsTableSelect,
    currentTicketId,
    loading,
    isCurrentTicketInWork,
    isCurrentTicketClosed,
    onManagerITTableSelect,
    currentTaskId,
    files,
    onAddTicketFile,
    onDeleteTicketFile,
    hasItNoteChanged,
    hasTaskDataChanged,
    onCancelTakeIntoWorkBtnClick,
    currentTicket,
  } = useDevTicketsList();

  return (
    <div
      className="grid2ColFirstBigger fGrow pb fShrink maxH100"
      style={{gridTemplateColumns: "minmax(0, 1fr) 30%", maxHeight: "100%"}}
    >
      <div className="fcCol gap0_5 pt5  maxH100">
        <ReactTable
          columns={ticketsTableColumns}
          defaultData={ticketsTableData}
          className="fGrow scrollX scrollY fShrink"
          current={currentTicketId}
          onSelect={onTicketsTableSelect}
          loading={loading}
          style={{
            height: "13em",
          }}
        />
        <ToolTip title={currentTicketId ? "appeal text" : ""} className="w100 ">
          <TextAreaInput name="ticketText" height="8rem" placeholder={t("appeal text")} readOnly className="fcCol" />
        </ToolTip>

        <Divider />
        <div className="w100 fc nowrap">
          <div className="fGrow fcCol gap0_5 h100">
            <div className="row w100 jcsb aic">
              <FunctionButton
                name={isCurrentTicketInWork ? "Back to status 'New'" : "Take into work"}
                onClick={isCurrentTicketInWork ? onCancelTakeIntoWorkBtnClick : onTakeIntoWorkBtnClick}
                autoWidth
                disabled={!currentTicketId || isCurrentTicketClosed || currentTicket?.tasks?.length > 1}
              />
              <div className="row aife gap">
                <Select
                  label={t("IT Manager")}
                  options={iTManagersOptions}
                  name="itManagerId"
                  width="20rem"
                  optionsAbove
                  disabled={!currentTicketId || !isCurrentTicketInWork}
                  required
                />
                <DateInput
                  label={t("Plan Date")}
                  name="planDate"
                  fromCurrentDate
                  disabled={!currentTicketId || !isCurrentTicketInWork}
                  required
                />
                {/* <CheckBox
                  type="checkbox"
                  name="isDone"
                  disabled={!currentTicketId || !isCurrentTicketInWork || !currentTaskId}
                  tooltipMessage={currentTicketId ? "sign task ready" : ""}
                /> */}
              </div>
            </div>
            <div className=" w100 h100">
              <TextAreaInput
                name="itNote"
                placeholder={t("appeal IT response text")}
                style={{height: "100%", width: "100%"}}
                disabled={!currentTicketId || !isCurrentTicketInWork}
              />
              {/* <div className="fc aic" style={{height: "8rem", marginTop: "5px"}}>
                <ReactTable
                  columns={managerITTableColumns}
                  defaultData={managerITTableData}
                  current={currentTaskId}
                  onSelect={onManagerITTableSelect}
                  loading={currentTicketId && loading}
                />
              </div> */}
            </div>
          </div>
          <div className="fcCol jcsa gap h100 pl">
            <ImageButton
              src={SaveIcon}
              size={2.2}
              onClick={onSaveBtnClick}
              disabled={!currentTicketId || !isCurrentTicketInWork || !(hasItNoteChanged || hasTaskDataChanged)}
            />
            <PdfFilePreview
              files={files}
              delFunc={onDeleteTicketFile}
              addFunc={onAddTicketFile}
              disable={!currentTicketId || !isCurrentTicketInWork}
              loading={loading}
              width={2.6}
              height={2}
              accept="*"
            />
            <ImageButton
              src={ReadyIcon}
              size={2.2}
              onClick={onReadyBtnClick}
              disabled={!currentTicketId || managerITTableData?.length < 1}
              tooltipMessage={isCurrentTicketClosed ? "reopen ticket" : currentTicketId ? "sign ready" : ""}
              className={isCurrentTicketClosed ? "pressed" : ""}
            />
          </div>
        </div>
      </div>
      <div className="kpPreview__box pdfViewerContain w100 h100">
        <NewPdfViewer fileArray={fileArray ?? []} />
      </div>
    </div>
  );
};

export default DevTicketsListBody;
