import React from "react";

const Divider = ({className = "", noMargin = false, margin = "10px"}) => {
  return (
    <div
      className={`w100 ${className}`}
      style={{
        border: "1px solid #A3B9D9",
        height: 0,
        marginTop: noMargin ? 0 : margin,
        marginBottom: noMargin ? 0 : margin,
      }}
    ></div>
  );
};

export default Divider;
