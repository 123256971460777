import {useTranslation} from "react-i18next";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {apiUrl} from "../../../../../api/baseURL";
import ToolTip from "../../../../../utilities/ToolTip";

const PdfPreviewItem = ({file, delFunc}) => {
  const {t} = useTranslation();
  const url = typeof file === "string" ? file : file.fileUrl;
  const format = url.split(".").pop();

  return (
    <div className="previewBoxItem">
      <div>
        {format === "pdf" ? (
          <p style={{width: "10em", wordWrap: "break-word"}}>{url.split("/").pop()}</p>
        ) : (
          <img src={url ? `${apiUrl}/${url}` : ""} alt="" width="200px" height="200px" draggable="false" />
        )}
      </div>
      <div style={{position: "absolute", top: "-4px", right: "-4px", cursor: "pointer"}}>
        <ToolTip title={t("Delete image")}>
          <CancelOutlinedIcon
            onClick={() => {
              Swal.fire({
                title: "",
                text: t("Sure"),
                icon: "question",
                confirmButtonText: t("Yes"),
                showCancelButton: true,
                cancelButtonText: t("No"),
                customClass: {
                  popup: "zindex",
                  container: "zindex",
                  htmlContainer: "zindex",
                },
              }).then(value => {
                if (value.isConfirmed) {
                  delFunc(typeof file === "string" ? url : file.fileId);
                }
              });
            }}
            sx={{background: "aliceblue", borderRadius: "50%"}}
          />
        </ToolTip>
      </div>
    </div>
  );
};

PdfPreviewItem.propTypes = {
  url: PropTypes.string,
  delFunc: PropTypes.func,
};

export default PdfPreviewItem;
