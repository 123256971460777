import {t} from "i18next";

import DeleteIcon from "../../../images/icons/deleteTrash.png";
import SaveIcon from "../../../images/icons/SaveDisket.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import NewPdfViewer from "../../conteiners/DocumentView/NewPdfViewer";
import ReactTable from "../../conteiners/ReactTable";
import {TextAreaInput} from "../../Inputs";
import {ButtonSelect} from "../../Inputs/ButtonSelect";
import PdfFilePreview from "../../Inputs/fileSelect copy/preview/PdfPreview";

import useDevITBody from "./useDevITBody";

const DevITBody = () => {
  const {
    fileArray,
    ticketTypesOptions,
    ticketsTableColumns,
    ticketsTableData,
    onTicketsTableSelect,
    onSaveBtnClick,
    onDeleteBtnClick,
    currentTicketId,
    loading,
    onAddTicketFile,
    onDeleteTicketFile,
    files,
    isSaveBtnDisabled,
    isCurrentTicketClosed,
    isCurrentTicketInWork,
  } = useDevITBody();

  return (
    <div
      className="grid2ColFirstBigger fGrow gap w100 mt0_5"
      style={{gridTemplateColumns: "minmax(0, 1fr) 30%", marginTop: "5px"}}
    >
      <div className="fcCol gap0_5 w100" style={{gap: "0.5em"}}>
        <div className="w100 row">
          <div className="fGrow w100">
            <div className="row w100">
              <ButtonSelect
                label="Add appeal type"
                name="ticketTypeId"
                options={ticketTypesOptions}
                disabled={isCurrentTicketInWork || isCurrentTicketClosed}
              />
            </div>
            <TextAreaInput
              name="ticketText"
              height="7rem"
              placeholder={t("Enter appeal text (5000 chars with spaces)")}
              disabled={isCurrentTicketInWork || isCurrentTicketClosed}
            />
          </div>
          <div className="pt5 fcCol gap aic jcsb" style={{paddingTop: "1em"}}>
            <PdfFilePreview
              files={files}
              delFunc={onDeleteTicketFile}
              addFunc={onAddTicketFile}
              disable={!currentTicketId || isCurrentTicketClosed}
              // loading={loading}
              accept="*"
            />
            <ImageButton src={SaveIcon} size={1.8} onClick={onSaveBtnClick} disabled={isSaveBtnDisabled} />
            <ImageButton
              src={DeleteIcon}
              size={2}
              onClick={onDeleteBtnClick}
              tooltipMessage={fileArray?.length > 0 ? "Delete files first" : ""}
              disabled={!currentTicketId || isCurrentTicketClosed || isCurrentTicketInWork || fileArray?.length > 0}
            />
          </div>
        </div>
        <Divider />
        <ReactTable
          columns={ticketsTableColumns}
          defaultData={ticketsTableData}
          onSelect={onTicketsTableSelect}
          className="fGrow scrollX scrollY fShrink"
          current={currentTicketId}
          loading={loading}
          style={{height: "19rem"}}
        />
        <TextAreaInput name="itNote" placeholder={t("IT note")} height="minmax(5rem, 8rem)" readOnly />
      </div>
      <div className="kpPreview__box pdfViewerContain w100 h100">
        <NewPdfViewer fileArray={fileArray ?? []} />
      </div>
    </div>
  );
};

export default DevITBody;
