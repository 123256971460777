import filterOffImg from "../../../images/icons/8679938_filter_off_line_icon.png";
import filterImg from "../../../images/icons/8680055_filter_line_icon.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";

const FilterButton = ({onFilter, delFilter, questionTitle = "", areFiltersDefined = false}) => {
  return (
    <div className="flex_container nowrap" style={{height: "2.9em"}}>
      <ImageButton
        src={filterImg}
        alt="filter"
        tooltipMessage={questionTitle}
        onClick={() => onFilter()}
        size={2}
        className={"filterBtn " + (areFiltersDefined ? "definedFilters" : "")}
      />

      <ImageButton src={filterOffImg} alt="filter off" onClick={delFilter} size={2} />
    </div>
  );
};

export default FilterButton;
