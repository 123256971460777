// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfViewerContain img:not(.modal_close_btn) {
  width: 100%;
  object-fit: contain;
}

.grid2ColFirstBigger {
  display: grid;
  grid-template: 1fr/2fr 1fr;
  column-gap: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/DevIT/src/DevIt.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;AAAJ;;AAGA;EACE,aAAA;EACA,0BAAA;EACA,gBAAA;EACA,WAAA;AAAF","sourcesContent":[".pdfViewerContain {\n  img:not(.modal_close_btn) {\n    width: 100%;\n    object-fit: contain;\n  }\n}\n.grid2ColFirstBigger {\n  display: grid;\n  grid-template: 1fr / 2fr 1fr;\n  column-gap: 10px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
