import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";

import AlarmIcon from "../../images/icons/alarm.svg";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import {DateInput, Select, TextAreaInput, TextInput} from "../Inputs";
import FilterButton from "../SamplesList/SampleParts/FilterButton";

import ChosenFiltersLine from "./utils/ChosenFiltersLine";
import useSampleFilter from "./useSampleFilter";

const SampleFilter = ({fetchData, removeFilter, children, storageKey}) => {
  const {t} = useTranslation();

  const {
    initialValues,
    factoriesOptions,
    statusOptions,
    officesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    categoriesOptions,
    texturesOptions,
    brandManagerOptions,
    accManagerOptions,
    areFiltersDefined,
    onFilter,
    onDelFilters,
    onGroupSelect,
    onMaterialSelect,
    filtersRow,
  } = useSampleFilter(fetchData, removeFilter, storageKey);

  const filterOnEnter = (e, values) => {
    if (e.code === "Enter") {
      onFilter(values);
    }
  };

  return (
    <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} enableReinitialize>
      {({values, setFieldValue}) => (
        <Form className="" style={{width: "100%"}}>
          <div>
            <div
              style={{
                gap: "1em",
                alignItems: "flex-start",
                width: "100%",
              }}
              className="fc jcfs autolabel nowrap"
            >
              <div className="flex_container aist gap5" style={{flexDirection: "column"}}>
                <FilterButton
                  onFilter={() => onFilter(values)}
                  delFilter={() => onDelFilters(values)}
                  questionTitle={t("Filter")}
                  areFiltersDefined={areFiltersDefined}
                />
                <ImageButton src={AlarmIcon} width={2} disabled />
                {children}
              </div>
              <div className="samp_filter_block">
                <div className="samp_filter_block_left">
                  <Select
                    label={t("Factory")}
                    name="factoryId"
                    options={factoriesOptions}
                    multi={true}
                    width="100%"
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("factory", option?.title || null)}
                  />
                  <TextInput
                    label="kitSKU №"
                    name="kitSkuNumber"
                    width="100%"
                    generalClassName="samp_filter_item"
                    labelStyles={{width: "10em"}}
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <TextInput
                    label="SKU №"
                    name="skuNumber"
                    width="100%"
                    generalClassName="samp_filter_item"
                    labelStyles={{width: "10em"}}
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <Select
                    label={t("Status*")}
                    name="statusId"
                    options={statusOptions}
                    multi={true}
                    width="100%"
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("status", option?.title || null)}
                  />
                  <Select
                    label={t("Offic")}
                    name="officeId"
                    options={officesOptions}
                    multi={true}
                    width="100%"
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("office", option?.title || null)}
                  />
                  <Select
                    label={t("Smp g")}
                    name="groupId"
                    options={groupsOptions}
                    width="100%"
                    multi={true}
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    makeEffect={option => {
                      onGroupSelect(option.value);
                      setFieldValue("group", option?.title || null);
                    }}
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <Select
                    label={t("sgr")}
                    name="subgroupId"
                    options={subGroupsOptions}
                    width="100%"
                    multi={true}
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    disabled={!values.groupId}
                    // disabled={calcSubGroupDisabled(values)}
                    question={t("subgroup filter tooltip")}
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("subgroup", option?.title || null)}
                  />
                  <Select
                    label={t("material")}
                    name="materialId"
                    options={materialsOptions}
                    width="100%"
                    multi={true}
                    minFilterLength={1}
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    makeEffect={option => {
                      onMaterialSelect(option.value);
                      setFieldValue("material", option?.title || null);
                    }}
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <Select
                    label={t("categ")}
                    name="categoryId"
                    options={categoriesOptions}
                    width="100%"
                    multi={true}
                    minFilterLength={1}
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    disabled={!values.materialId}
                    // disabled={calcIsCategoryDisabled(values)}
                    question={t("category filter tooltip")}
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("category", option?.title || null)}
                  />
                  <Select
                    label={t("texture")}
                    name="textureId"
                    options={texturesOptions}
                    width="100%"
                    multi={true}
                    generalClassName="samp_filter_item"
                    labelWidth="10em"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("texture", option?.title || null)}
                  />

                  {/* <div className="samp_filter_area_row"> */}
                  <TextAreaInput
                    label=""
                    name="collection"
                    placeholder={t("search") + " - " + t("col")}
                    width="19em"
                    height="4em"
                    className="samp_filter_item_two"
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <TextAreaInput
                    label=""
                    name="color"
                    placeholder={t("search") + " - " + t("Color")}
                    width="19em"
                    height="4em"
                    className="samp_filter_item_two"
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <TextAreaInput
                    label=""
                    name="name"
                    placeholder={t("search") + " - " + t("samp nom")}
                    width="19em"
                    height="4em"
                    className="samp_filter_item_two"
                    onKeyDown={e => filterOnEnter(e, values)}
                  />
                  <Select
                    label={t("BM")}
                    name="brandManagerId"
                    options={brandManagerOptions}
                    width="100%"
                    multi={true}
                    generalClassName="samp_filter_item_two"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("brandManager", option?.title || null)}
                    labelWidth="10em"
                  />
                  <Select
                    label={t("acc m")}
                    name="managerId"
                    options={accManagerOptions}
                    width="100%"
                    multi={true}
                    generalClassName="samp_filter_item_two"
                    labelWidth="10em"
                    onKeyDown={e => filterOnEnter(e, values)}
                    makeEffect={option => setFieldValue("manager", option?.title || null)}
                  />
                </div>

                <div className="period_nom_wrapper">
                  <div className="period_nom">{t("acc per")}</div>
                  <div className="request-date-wrapper">
                    <DateInput label={t("From")} name="dateStart" />
                    <DateInput label={t("To")} name="dateEnd" />
                  </div>
                </div>
              </div>
            </div>
            <ChosenFiltersLine filtersRow={filtersRow} onFilter={onFilter} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

SampleFilter.propTypes = {
  children: PropTypes.node,
};

export default SampleFilter;
