// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caruselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.caruselItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image_carusel_custom_style .next {
  right: "-60px";
}
.image_carusel_custom_style .prev {
  left: 0;
}

.MuiCarouselItem-root {
  background-color: #eee;
}
.MuiCarouselItem-root .css-1abc02a {
  left: -200px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/fileSelect%20copy/slider/ImgSlider.scss","webpack://./src/Components/Inputs/fileSelect copy/slider/ImgSlider.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACJ,WAAA;EACQ,YAAA;ACCR;ADAI;EACI,WAAA;EACA,YAAA;EACD,mBAAA;ACEP;;ADOI;EACI,cAAA;ACJR;ADOI;EACI,OAAA;ACLR;;ADSA;EACI,sBAAA;ACNJ;ADOI;EACI,YAAA;ACLR","sourcesContent":[".caruselItem{\n    display: flex;\n    justify-content: center;\n    align-items: center;\nwidth: 100%;\n        height: 100%;\n    img{\n        width: 100%;\n        height: 100%;\n       object-fit: contain;\n        // object-fit: fill;\n        // max-width: 500px;\n        // max-height: 500px; \n    }\n}\n\n.image_carusel_custom_style{\n\n    .next {\n        right: '-60px'\n    }\n    // Applies to the \"prev\" button wrapper\n    .prev {\n        left: 0\n    }\n}\n\n.MuiCarouselItem-root {\n    background-color: #eee;\n    .css-1abc02a{\n        left: -200px;\n    }\n  }",".caruselItem {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n.caruselItem img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.image_carusel_custom_style .next {\n  right: \"-60px\";\n}\n.image_carusel_custom_style .prev {\n  left: 0;\n}\n\n.MuiCarouselItem-root {\n  background-color: #eee;\n}\n.MuiCarouselItem-root .css-1abc02a {\n  left: -200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
