import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import useColumns from "../../../hook/useColumns";
import {getSuccess} from "../../../slices/UserSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {swalCustom} from "../../../utilities/swalCustom";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";

// import translit from "ua-en-translit";
// import {dateFormat} from "../../utilities/dateFormat";
// import {swalCustom} from "../../utilities/hooks/swalCustom";
// import {toaster} from "../../utilities/hooks/toaster";
// import {makeTableNameFromPersona} from "../../utilities/makeNameFromPersona";
// import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";
import {APPEAL_LIST_TABLE_COLUMNS} from "./constants";
import {
  addNewTicket,
  addNewTicketFile,
  clearCurrentTicket,
  deleteTicket,
  deleteTicketFile,
  fetchAllTickets,
  fetchAllTicketTypes,
  fetchDevITFilters,
  fetchTicket,
  selectAllDevITTickets,
  selectAllDevITTicketTypes,
  selectDevITFilters,
  selectDevITLoading,
  selectDevITTicket,
  updateTicket,
} from "./DevITSlice";

export const expandedTextArray = textArr => {
  if (!textArr || textArr.length === 0) return " - ";

  return textArr?.length > 1
    ? {
        text: textArr.join("\n"),
        expand: true,
      }
    : textArr?.[0] || " - ";
};
export function makeTableNameFromPersona({persona, language}) {
  if (!persona || !language) return "";

  return `${language === "en" ? persona.engLastName : persona.lastName} ${
    language === "en" ? persona.engFirstName : persona.firstName
  }`;
}
const useDevITBody = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();
  const {initialValues, values, setValues} = useFormikContext();
  const {ticketTypeId, ticketText} = values;
  const {filters} = useFiltersContext();

  const allTickets = useSelector(selectAllDevITTickets);
  const currentTicket = useSelector(selectDevITTicket);
  const ticketTypes = useSelector(selectAllDevITTicketTypes);
  const fetchedFilters = useSelector(selectDevITFilters);
  const typesFiltersOptions =
    fetchedFilters?.types?.map(({typeId, nameEng, nameUkr}) => ({
      value: typeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const loading = useSelector(selectDevITLoading);

  const fileArray = currentTicket?.files?.map(({fileUrl}) => fileUrl) ?? [];
  const files = currentTicket?.files ?? [];
  const [currentTicketId, setCurrentTicketId] = useState(null);

  useEffect(() => {
    if (currentTicketId) {
      dispatch(fetchTicket({ticketId: currentTicketId}));
    }
  }, [currentTicketId]);

  useEffect(() => {
    if (currentTicket?.ticketId) {
      setValues(prev => ({
        ...prev,
        itNote: currentTicket?.note,
        ticketText: currentTicket?.text,
        ticketTypeId: currentTicket?.typeId,
      }));
    } else {
      setCurrentTicketId(null);
      setValues(initialValues);
    }
  }, [currentTicket?.ticketId]);

  useEffect(() => {
    dispatch(fetchAllTicketTypes());
    dispatch(fetchDevITFilters({params: {}})).then(() => {
      onFilterChange(filters);
    });
  }, []);

  const ticketTypesOptions =
    ticketTypes?.map(({typeId, nameEng, nameUkr}) => ({value: typeId, title: language === "en" ? nameEng : nameUkr})) ??
    [];

  const ticketsTableColumns = useColumns(APPEAL_LIST_TABLE_COLUMNS);
  const ticketsTableData =
    allTickets?.map(
      (
        {
          ticketId,
          type: {nameEng: typeNameEng, nameUkr: typeNameUkr},
          block: {nameEng: blockNameEng, nameUkr: blockNameUkr},
          status: {nameEng: statusNameEng, nameUkr: statusNameUkr},
          creator: {lastName, firstName},
          text,
          note,
          startDate,
          deadline,
          finishDate,
          createdAt,
          tasks,
        },
        index,
      ) => ({
        number: ++index,
        id: ticketId,
        date: dateFormat(createdAt),
        appealType: language === "en" ? typeNameEng : typeNameUkr,
        about: text,
        block: language === "en" ? blockNameEng : blockNameUkr,
        manager: lastName + " " + firstName,
        status: language === "en" ? statusNameEng : statusNameUkr,
        managerIT: tasks?.[0] ? tasks?.[0]?.performer?.lastName + " " + tasks?.[0]?.performer?.firstName : " ",
        startDate: dateFormat(startDate) ?? "-",
        planDate: tasks?.[0] ? dateFormat(tasks?.[0]?.deadline) ?? "-" : "-",
        finishDate: dateFormat(finishDate) ?? "-",
        isDone: Boolean(finishDate),
        noteIT: note ?? "-",
      }),
    ) ?? [];

  const onTicketsTableSelect = ticketId => {
    setCurrentTicketId(ticketId);
    if (!ticketId) {
      dispatch(clearCurrentTicket());
    }
  };

  const onAddTicketFile = arrFiles => {
    if (!currentTicketId) return;
    const formdata = new FormData();
    let file = arrFiles[0];
    // let filename = translit(file.name);
    let filename = file.name;
    formdata.append("file", file, filename);

    if (formdata.has("file")) {
      dispatch(addNewTicketFile({ticketId: currentTicketId, body: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess("Ticket file added");
        }
      });
    }
  };

  const onDeleteTicketFile = fileId => {
    if (!currentTicketId) return;
    dispatch(deleteTicketFile({fileId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess("Ticket file deleted");
      }
    });
  };

  const isCurrentTicketInWork = currentTicket?.status?.statusId === 2;
  const isCurrentTicketClosed = currentTicket?.status?.statusId === 3;
  const hasTicketTextChanged = currentTicket?.text !== ticketText && ticketText;
  const hasTicketTypeChanged = currentTicket?.typeId !== ticketTypeId && ticketTypeId;
  const isSaveBtnDisabled =
    isCurrentTicketInWork ||
    isCurrentTicketClosed ||
    (!currentTicketId && (!ticketTypeId || !ticketText)) ||
    (currentTicketId && !(hasTicketTextChanged || hasTicketTypeChanged));

  const onSaveBtnClick = () => {
    //save
    if (!currentTicketId) {
      if (!ticketTypeId || !ticketText) return;
      dispatch(addNewTicket({body: {typeId: ticketTypeId, text: ticketText}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess("New ticket added");
          setCurrentTicketId(resp.payload.ticketId);
          dispatch(fetchDevITFilters({params: filters}));
        }
      });
    }

    //edit
    if (currentTicketId) {
      dispatch(updateTicket({ticketId: currentTicketId, body: {typeId: ticketTypeId, text: ticketText}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess("Ticket updated");
        }
      });
    }
  };

  const onDeleteBtnClick = () => {
    if (!currentTicketId) return;
    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteTicket({ticketId: currentTicketId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            getSuccess("Ticket deleted");
            setCurrentTicketId(null);
            dispatch(clearCurrentTicket());
            setValues(initialValues);
          }
        });
      },
    });
  };

  const onFilterChange = filters => {
    if (!filters) {
      dispatch(fetchAllTickets());
      return;
    }

    dispatch(fetchAllTickets({params: filters}));
  };

  return {
    fileArray,
    ticketTypesOptions,
    ticketsTableColumns,
    ticketsTableData,
    onSaveBtnClick,
    onDeleteBtnClick,
    currentTicketId,
    onTicketsTableSelect,
    loading,
    onAddTicketFile,
    typesFiltersOptions,
    onFilterChange,
    onDeleteTicketFile,
    files,
    isSaveBtnDisabled,
    isCurrentTicketClosed,
    isCurrentTicketInWork,
    hasTicketTextChanged,
  };
};

export default useDevITBody;
