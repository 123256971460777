import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../api/axios.hook";

const initialState = {
  isModalOpen: false,
  tickets: [],
  ticket: null,

  filters: [],

  ticketTypes: [],
  ticketStatuses: [],

  loading: false,
  error: false,
};

//*** Tickets */
export const fetchAllTickets = createAsyncThunk("devIT/fetchAllTickets", async ({params}) =>
  fetchList("/dev/tickets/getAll", "get", {params}),
);
export const fetchTicket = createAsyncThunk("devIT/fetchTicket", async ({ticketId}) =>
  fetchList(`/dev/tickets/get/${ticketId}`, "get"),
);
export const addNewTicket = createAsyncThunk("devIT/addNewTicket", async ({body}) =>
  fetchData(`/dev/tickets/add`, "post", body),
);
export const updateTicket = createAsyncThunk("devIT/updateTicket", async ({ticketId, body}) => {
  if (!ticketId) return;
  return fetchData(`/dev/tickets/update/${ticketId}`, "patch", body);
});
export const deleteTicket = createAsyncThunk("devIT/deleteTicket", async ({ticketId}) => {
  if (!ticketId) return;
  return fetchData(`/dev/tickets/remove/${ticketId}`, "delete");
});

//** Filters */
export const fetchDevITFilters = createAsyncThunk("devIT/fetchDevITFilters", async ({params}) =>
  fetchList("/dev/tickets/filters/getAll", "get", {params}),
);

//** Types */
export const fetchAllTicketTypes = createAsyncThunk("devIT/fetchAllTicketTypes", async () =>
  fetchList("/dev/tickets/types/getAll", "get"),
);

//** Statuses */
export const fetchAllTicketStatuses = createAsyncThunk("devIT/fetchAllTicketStatuses", async () =>
  fetchList("/dev/tickets/statuses/getAll", "get"),
);

//** Files */
export const addNewTicketFile = createAsyncThunk("devIT/addNewTicketFile", async ({ticketId, body}) => {
  if (!ticketId) return;
  return fetchData(`/dev/tickets/files/add/${ticketId}`, "post", body);
});
export const deleteTicketFile = createAsyncThunk("devIT/deleteTicketFile", async ({fileId}) => {
  if (!fileId) return;
  return fetchData(`/dev/tickets/files/remove/${fileId}`, "delete");
});

//** Tasks */
export const addNewTicketTask = createAsyncThunk("devIT/addNewTicketTask", async ({ticketId, body}) => {
  if (!ticketId) return;
  return fetchData(`/dev/tickets/tasks/add/${ticketId}`, "post", body);
});
export const updateTicketTask = createAsyncThunk("devIT/updateTicketTask", async ({taskId, body}) => {
  if (!taskId) return;
  return fetchData(`/dev/tickets/tasks/update/${taskId}`, "patch", body);
});
export const deleteTicketTask = createAsyncThunk("devIT/deleteTicketTask", async ({taskId}) => {
  if (!taskId) return;
  return fetchData(`/dev/tickets/tasks/remove/${taskId}`, "delete");
});

export const DevIT_STORE_NAME = "devIT";

const FinSettingsSlice = createSlice({
  name: DevIT_STORE_NAME,
  initialState,
  reducers: {
    setDevItModalFlag: (state, {payload}) => {
      state.isModalOpen = payload;
    },

    clearCurrentTicket: state => {
      state.ticket = null;
    },
  },
  extraReducers: builder => {
    builder
      //*** Tickets */
      .addCase(fetchAllTickets.fulfilled, (state, {payload}) => {
        state.tickets = payload;
      })
      .addCase(fetchTicket.fulfilled, (state, {payload}) => {
        state.ticket = payload;
      })
      .addCase(addNewTicket.fulfilled, (state, {payload}) => {
        state.ticket = payload;
        state.tickets = [payload, ...state.tickets];
      })
      .addCase(updateTicket.fulfilled, (state, {payload}) => {
        state.ticket = payload;
        state.tickets = state.tickets.map(ticket => (ticket.ticketId === payload.ticketId ? payload : ticket));
      })
      .addCase(deleteTicket.fulfilled, (state, {meta: {arg}}) => {
        state.ticket = null;
        state.tickets = state.tickets.filter(ticket => ticket.ticketId !== arg.ticketId);
      })

      //** Filters */
      .addCase(fetchDevITFilters.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })

      //** Types */
      .addCase(fetchAllTicketTypes.fulfilled, (state, {payload}) => {
        state.ticketTypes = payload;
      })

      //** Statuses */
      .addCase(fetchAllTicketStatuses.fulfilled, (state, {payload}) => {
        state.ticketStatuses = payload;
      })

      //** Files */
      .addCase(addNewTicketFile.fulfilled, (state, {payload}) => {
        state.ticket = payload;
      })
      .addCase(deleteTicketFile.fulfilled, (state, {meta: {arg}}) => {
        const ticketFiles = state.ticket.files.filter(({fileId}) => fileId !== arg.fileId);

        state.ticket = {...state.ticket, files: ticketFiles};
      })

      //** Tasks */
      .addCase(addNewTicketTask.fulfilled, (state, {payload}) => {
        state.ticket = payload;
        state.tickets = state.tickets.map(ticket => (ticket.ticketId === payload.ticketId ? payload : ticket));
      })
      .addCase(updateTicketTask.fulfilled, (state, {payload}) => {
        state.ticket = payload;
        state.tickets = state.tickets.map(ticket => (ticket.ticketId === payload.ticketId ? payload : ticket));
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(DevIT_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(DevIT_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(DevIT_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.loading = false;
  state.error = false;
}

function handlePending(state) {
  state.loading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.loading = false;
  state.error = error;
}

const {actions, reducer} = FinSettingsSlice;

export const {clearCurrentTicket, setDevItModalFlag} = actions;

export default reducer;

// *** Selectors ***
export const selectAllDevITTickets = state => state[DevIT_STORE_NAME].tickets;
export const selectDevITTicket = state => state[DevIT_STORE_NAME].ticket;

export const selectDevITFilters = state => state[DevIT_STORE_NAME].filters;

export const selectAllDevITTicketTypes = state => state[DevIT_STORE_NAME].ticketTypes;
export const selectAllDevITTicketStatuses = state => state[DevIT_STORE_NAME].ticketStatuses;

export const selectDevITLoading = state => state[DevIT_STORE_NAME].loading;
export const selectDevITError = state => state[DevIT_STORE_NAME].error;

export const selectDevItModalFlag = state => state[DevIT_STORE_NAME].isModalOpen;
