import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ScrollMode, SpecialZoomLevel, Viewer, Worker} from "@react-pdf-viewer/core";
import {getFilePlugin} from "@react-pdf-viewer/get-file";
import {pageNavigationPlugin} from "@react-pdf-viewer/page-navigation";
import {printPlugin} from "@react-pdf-viewer/print";
import {toolbarPlugin} from "@react-pdf-viewer/toolbar";

import prevIcon from "../../../images/icons/Caret_Circle_Left.png";
import nextIcon from "../../../images/icons/Caret_Circle_Right.png";
import CloseBtn from "../../../utilities/Buttons/CloseBtn";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../utilities/ToolTip";
import ImgSlider from "../../Inputs/fileSelect copy/slider/NewImgSlider";
import Modal from "../../Modal/Modal";
import Spinner from "../../spinner/Spinner";

import {apiUrl} from "./../../../api/baseURL";
import hideIcon from "./src/closeEye.png";
import downloadIcon from "./src/download.png";
import fullScreenIcon from "./src/full_screen.png";
import nextDocIcon from "./src/next-doc.png";
import showIcon from "./src/openEye.png";
import prevDocIcon from "./src/prev-doc.png";
import printIcon from "./src/Printer.png";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "./src/DocumentView.scss";

const NewPdfViewer = ({fileArray, rotate = 0, hiding = true, printBtnEnable, onlyLocalFile = false, format = null}) => {
  const {t} = useTranslation();

  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFile, setShowFile] = useState(!hiding);
  const [numFiles, setNumFiles] = useState(0);

  const [prevFileDis, setPrevFileDis] = useState(true);
  const [nextFileDis, setNextFileDis] = useState(true);

  const [fileType, setFileType] = useState();

  const disabledViewBtn = fileArray && fileArray?.length !== 0 ? false : true;

  useEffect(() => {
    if (hiding) {
      setFile(null);
      setShowFile(false);
    }
    if (fileArray && fileArray.length !== 0) {
      let f = fileArray[0]?.split(".");
      setNumFiles(0);
      let calculatedFormat = f[f.length - 1].toUpperCase();
      if (format) {
        setFileType(format);
      } else if (calculatedFormat === "PDF") {
        setFileType(calculatedFormat);
      } else setFileType("IMG");
      if (!hiding) {
        changeFile(fileArray[0]);
        setShowFile(true);
      }
    } else if (fileArray?.length < 1 && !hiding) {
      setFile(null);
    }
  }, [fileArray, hiding]);

  useEffect(() => {
    setNumFiles(0);
    if (showFile) {
      if (!file) {
        changeFile(fileArray[numFiles]);
      } //demoPdf
    } else {
      setNextFileDis(true);
      setPrevFileDis(true);
    }
  }, [showFile]);

  const changeFile = file => {
    setFile(onlyLocalFile ? file : `${apiUrl}/${file}`);
    if (fileArray.length > 1 && numFiles !== fileArray.length - 1) setNextFileDis(false);
  };

  const showNextFile = () => {
    if (numFiles !== fileArray.length - 1) setNumFiles(num => (num = num + 1));
  };
  const showPrevFile = () => {
    if (numFiles !== 0) setNumFiles(num => (num = num - 1));
  };

  useEffect(() => {
    if (file) {
      if (numFiles === 0) setPrevFileDis(true);
      if (numFiles === fileArray.length - 1) setNextFileDis(true);
      if (numFiles < fileArray.length - 1) setNextFileDis(false);
      if (numFiles > 0 && numFiles <= fileArray.length - 1) setPrevFileDis(false);

      changeFile(fileArray[numFiles]);
    }
  }, [numFiles]);

  const renderFunc = fileType => {
    switch (fileType) {
      case "PDF":
        if (showModal) return <RenderFullScreenPdf file={file} />;
        else {
          return (
            <RenderPdfViewer
              file={file}
              prevFileDis={prevFileDis}
              showPrevFile={showPrevFile}
              nextFileDis={nextFileDis}
              showNextFile={showNextFile}
              rotate={rotate}
              printBtnEnable={printBtnEnable}
            />
          );
        }
      case "IMG":
        return (
          <RenderImgFilesViewer
            file={file}
            prevFileDis={prevFileDis}
            showPrevFile={showPrevFile}
            nextFileDis={nextFileDis}
            showNextFile={showNextFile}
          />
        );
      default:
        return null;
    }
  };

  const renderModalFunc = fileType => {
    switch (fileType) {
      case "PDF":
        return (
          <div style={{width: "70vw", maxHeight: "80vh"}}>
            <RenderFullScreenPdf file={file} />
          </div>
        );
      case "IMG":
        return <ImgSlider imgs={fileArray} width={"100%"} height={"600px"} />;
      default:
        return null;
    }
  };

  return (
    <div className="doc_view">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className="doc_view__container" style={{position: "absolute"}}>
          {!showModal && showFile && file && renderFunc(fileType)}
        </div>
        <div className="doc_view__options">
          {hiding &&
            (disabledViewBtn ? (
              <ImageButton
                src={showFile ? showIcon : hideIcon}
                alt="hide doc"
                width={1.5}
                height={1.5}
                disabled={disabledViewBtn}
                onClick={() => {
                  setShowFile(i => (i = !i));
                }}
                className="doc_view__visibility"
              />
            ) : (
              <ToolTip title={showFile ? t("Close document") : t("Open document")}>
                <ImageButton
                  src={showFile ? showIcon : hideIcon}
                  alt="hide doc"
                  width={1.5}
                  height={1.5}
                  disabled={disabledViewBtn}
                  onClick={() => {
                    setShowFile(i => (i = !i));
                  }}
                  className="doc_view__visibility"
                />
              </ToolTip>
            ))}

          {showFile && (
            <ToolTip title={t("Full screen")}>
              <ImageButton
                src={fullScreenIcon}
                alt="full screen"
                width={1.1}
                height={1.1}
                style={{opacity: "70%"}}
                disabled={file && fileArray && fileArray.length !== 0 ? false : true}
                onClick={() => {
                  setShowModal(i => (i = !i));
                }}
              />
            </ToolTip>
          )}
          {showModal && (
            <Modal active={showModal} setActive={setShowModal} height="90vh" width="90vw">
              <CloseBtn
                style={{top: "0.5em", right: "0.4em"}}
                onClick={() => {
                  setShowModal(false);
                }}
              />
              {showFile && file && renderModalFunc(fileType)}
            </Modal>
          )}

          {/* <div className="doc_view__download">
                    <p>download</p><img src={downloadIcon} alt="download" />
                </div> */}
          {/* <img src={printIcon} alt="print" /> */}
        </div>
      </Worker>
    </div>
  );
};

const RenderImgFilesViewer = props => {
  const {file, prevFileDis, showPrevFile, nextFileDis, showNextFile} = props;

  return (
    <>
      <div className="image_container_project-doc">
        <img
          src={file}
          alt=""
          draggable="false"
          //  style={{ objectFit: 'cover'}}
        />
      </div>
      <div className="page-control-bar">
        <div className="page-control-bar__container">
          <ImageButton
            src={prevIcon}
            alt="prev-page"
            width={1.5}
            height={1.5}
            // disabled={props.isDisabled}
            className="doc_control_btn "
            disabled={prevFileDis}
            onClick={showPrevFile}
          />

          <ImageButton
            src={nextIcon}
            alt="next-page"
            width={1.5}
            height={1.5}
            // disabled={props.isDisabled}
            className="doc_control_btn "
            disabled={nextFileDis}
            onClick={showNextFile}
          />
        </div>
      </div>
    </>
  );
};

const RenderPdfViewer = props => {
  const {t} = useTranslation();

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {GoToNextPage, GoToPreviousPage} = pageNavigationPluginInstance;

  const getFilePluginInstance = getFilePlugin();
  const {Download} = getFilePluginInstance;

  const printPluginInstance = printPlugin();
  const {Print} = printPluginInstance;

  // const fullScreenPluginInstance = fullScreenPlugin();
  // const { EnterFullScreenButton, EnterFullScreenMenuItem } = fullScreenPluginInstance;

  const {file, prevFileDis, showPrevFile, nextFileDis, showNextFile, rotate} = props;

  const renderError = ({message}) => {
    return <p className="label error_doc_view">{message}</p>;
  };

  return (
    <>
      <Viewer
        fileUrl={file}
        defaultScale={SpecialZoomLevel.PageFit}
        scrollMode={ScrollMode.Vertical}
        plugins={[pageNavigationPluginInstance, getFilePluginInstance, printPluginInstance]}
        renderError={renderError}
        renderLoader={percentages => <Spinner />}
        rotate={rotate}
        //    markRendered={markRendered}
        // onPageChange={({currentPage})=>{console.log(currentPage)}}
        //    onDocumentLoad={handleDocumentLoad}
      />

      <div className="page-control-bar">
        <div className="page-control-bar__container">
          <ImageButton
            src={prevDocIcon}
            alt="prev-doc"
            width={1.5}
            height={1.5}
            className="doc_control_btn "
            disabled={prevFileDis}
            onClick={showPrevFile}
          />
          <GoToPreviousPage>
            {props => (
              <ImageButton
                src={prevIcon}
                alt="prev-page"
                width={1.5}
                height={1.5}
                disabled={props.isDisabled}
                className="doc_control_btn "
                onClick={props.onClick}
              />
            )}
          </GoToPreviousPage>
          <GoToNextPage>
            {props => (
              <ImageButton
                src={nextIcon}
                alt="next-page"
                width={1.5}
                height={1.5}
                disabled={props.isDisabled}
                className="doc_control_btn "
                onClick={props.onClick}
              />
            )}
          </GoToNextPage>

          <ImageButton
            src={nextDocIcon}
            alt="next-doc"
            width={1.5}
            height={1.5}
            className="doc_control_btn "
            disabled={nextFileDis}
            onClick={showNextFile}
          />
        </div>
      </div>
      {/* <div style={{position:"absolute", bottom:'1em', right:'0'}}><EnterFullScreenButton /></div>  */}

      <Download>
        {props => (
          <div className="doc_view__download pdf" onClick={props.onClick}>
            <ToolTip title={t("Download")}>
              <ImageButton src={downloadIcon} alt="download" width={1.8} height={1.8} />
            </ToolTip>
          </div>
        )}
      </Download>
      {props?.printBtnEnable && (
        <Print>
          {props => (
            <div
              className="doc_view__print pdf"
              onClick={props.onClick}
              style={{position: "absolute", bottom: "-2.3em", right: "4em", zIndex: "100"}}
            >
              <ToolTip title={t("Print")}>
                <ImageButton src={printIcon} alt="print" width={1.8} height={1.8} />
              </ToolTip>
            </div>
          )}
        </Print>
      )}
    </>
  );
};

const RenderFullScreenPdf = props => {
  const {file} = props;
  const toolbarPluginInstance = toolbarPlugin();
  const {Toolbar} = toolbarPluginInstance;

  const renderError = ({message}) => {
    return <p className="label error_doc_view">{message}</p>;
  };
  return (
    <>
      <Toolbar>
        {props => {
          const {
            CurrentPageInput,
            Download,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,

            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = props;
          return (
            <div style={{display: "flex"}}>
              <div style={{padding: "0px 2px"}}>
                <ShowSearchPopover />
              </div>
              <div style={{padding: "0px 2px"}}>
                <ZoomOut />
              </div>
              <div style={{padding: "0px 2px"}}>
                <Zoom />
              </div>
              <div style={{padding: "0px 2px"}}>
                <ZoomIn />
              </div>
              <div style={{padding: "0px 2px", marginLeft: "auto"}}>
                <GoToPreviousPage />
              </div>
              <div
                style={{
                  padding: "0px 2px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <div style={{padding: "0px 2px"}}>
                <GoToNextPage />
              </div>
              <div style={{padding: "0px 2px"}}>
                <Download />
              </div>
              {/* <div style={{padding: "0px 2px"}}>
                <Print />
              </div> */}
            </div>
          );
        }}
      </Toolbar>
      <Viewer
        fileUrl={file}
        defaultScale={SpecialZoomLevel.PageWidth}
        scrollMode={ScrollMode.Vertical}
        plugins={[toolbarPluginInstance]}
        renderError={renderError}
        renderLoader={percentages => <Spinner />}
        //    markRendered={markRendered}
        // onPageChange={({currentPage})=>{console.log(currentPage)}}
        //    onDocumentLoad={handleDocumentLoad}
      />
    </>
  );
};

export default NewPdfViewer;
