import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import {t} from "i18next";
import {isEmpty} from "lodash";
import Swal from "sweetalert2";

import {fetchData} from "../../../hook/axios.hook";
import useColumns from "../../../hook/useColumns";
import {
  addNewKit,
  addNewKitImages,
  deleteKit,
  deleteKitImage,
  dublicateKit,
  updateKit,
} from "../../../slices/SampleFunctionalSlice";
import {
  addPageTab,
  closeCurrentTab,
  getInfo,
  getSuccess,
  selectPageTabs,
  updatePageTabProps,
} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../Inputs";
import {SampleUniModTableColumns} from "../../SamplesList/constants/columns";
import samplesForKitModalDataService from "../../SamplesList/constants/samplesForKitModalDataService";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";

import QR from "./QR";

const KitForm = ({kit, onSubmit, formInitialValues, formType}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {
      kitSku: kit?.skuNumber,
      setDescArea: kit?.name,
    };
  }, [kit]);

  const readOnly = !onSubmit;

  const {setValues, values, dirty, setFieldError, errors} = useFormikContext();

  const onCancel = () => {
    if (values?.kitId) {
      Swal.fire({
        title: "",
        text: t("del kit"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteKit(values?.kitId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              getSuccess("Kit deleted");
              if (kit?.images.length > 0) {
                dispatch(updatePageTabProps({pageType: formType, props: {kit: {...kit, images: []}}}));
              }
              setValues({...initialValues, kitId: null});
            }
          });
        }
      });
    } else if (formType === TYPE_FORMS.UPDATE_KIT) {
      setValues(formInitialValues);
      // dispatch(updateKit({kitId: kit.kitId, data: {name: values.setDescArea, skuNumber: values.kitSku}})).then(res => {
      //   if (res.meta.requestStatus === "fulfilled") {
      //     getInfo(t("ins updated"));
      //     setValues({...formInitialValues});
      //   }
      // });
    } else {
      setValues(formInitialValues);
    }
  };

  const addImages = formdata => {
    dispatch(addNewKitImages({kitId: kit?.kitId || values?.kitId, formdata})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(updatePageTabProps({pageType: formType, props: {kit: {...kit, images: res.payload}}}));
      }
    });
  };

  const delImg = imageId => {
    dispatch(deleteKitImage({imageId, kitId: kit?.kitId || values.kitId})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(updatePageTabProps({pageType: formType, props: {kit: {...kit, images: res.payload}}}));
      }
    });
  };

  const validate = () => {
    return new Promise((resolve, reject) => {
      if (formType === TYPE_FORMS.UPDATE_KIT) {
        let error = false;
        if (values.kitSku === "") {
          setFieldError("kitSku", t("it must be compleate"));
          error = true;
        }
        if (values.setDescArea === "") {
          setFieldError("setDescArea", t("it must be compleate"));
          error = true;
          reject();
        }
        if (error) reject();
      }
      resolve();
    });
  };

  return (
    <div className=" " style={{width: "100%"}}>
      <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
        <TextInput label={t("BM")} name="brandManager" width="17em" disabled />
      </div>
      <div className="border border_gap"></div>
      <div className="fdr mt mb">
        <div className="part_width flex_form jcsb">
          <div className="fdr">
            <TextInput
              label="kitSKU № *"
              name="kitSku"
              labelStyles={{width: "5em"}}
              generalClassName="leftLebel"
              disabled={readOnly}
            />
          </div>
          <div>
            <TextAreaInput
              label=""
              name="setDescArea"
              width="101%"
              placeholder={t("samp nom") + "*" + ": " + t("1000")}
              disabled={readOnly || values?.kitId}
            />
            {onSubmit && (
              <div className="fdr g1 jcfe mt">
                <FunctionButton
                  name={formType === TYPE_FORMS.DUBLICATE_KIT && !!kit ? "Upd" : "Save"}
                  onClick={() =>
                    validate()
                      .then(() => {
                        if (isEmpty(errors)) onSubmit(values);
                      })
                      .catch(() => {})
                  }
                  disabled={
                    (formType === TYPE_FORMS.DUBLICATE_KIT && !!kit && !dirty) ||
                    (formType !== TYPE_FORMS.DUBLICATE_KIT && !dirty) ||
                    values?.kitId ||
                    values?.setDescArea === "" ||
                    !isEmpty(errors)
                  }
                />
                <FunctionButton name={"Cancel"} onClick={onCancel} disabled={!dirty} />
              </div>
            )}
          </div>
        </div>
        <QR
          images={kit?.images}
          instance={{...kit, qrCodeUrl: kit?.qrCodeUrl || values?.kitQRcode}}
          addMethod={addImages}
          deleteMethod={delImg}
          readOnly={!(kit || values?.kitId) || !onSubmit}
        />
      </div>

      <div className="border border_gap"></div>
    </div>
  );
};

export default KitForm;

export const KitCreateForm = props => {
  const dispatch = useDispatch();
  const tabs = useSelector(selectPageTabs);

  const onCreate = values => {
    dispatch(addNewKit({name: values.setDescArea, skuNumber: values.kitSku})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("kit added"));
        dispatch(
          updatePageTabProps({
            pageType: props.formType,
            props: {
              formInitialValues: {
                setDescArea: res.payload.name,
                kitSku: res.payload.skuNumber,
                kitId: res.payload.kitId,
                kitQRcode: res.payload.qrCodeUrl,
              },
            },
          }),
        );
        const existIndex = tabs.findIndex(tab => tab.formType === TYPE_FORMS.CREATE_KIT_SAMPLE);
        const emptyVal = {
          factoryId: null,
          groupId: null,
          subgroupId: null,
          materialId: null,
          categoryId: null,
          textureId: null,
        };
        if (existIndex === -1) {
          dispatch(
            addPageTab({
              label: t("create k s"),
              askOnClose: true,
              controlled: true,
              formType: TYPE_FORMS.CREATE_KIT_SAMPLE,
              props: {
                kitId: res.payload.kitId,
                formInitialValues: emptyVal,
              },
            }),
          );
        } else {
          dispatch(
            updatePageTabProps({
              pageType: TYPE_FORMS.CREATE_KIT_SAMPLE,
              props: {
                kitId: res.payload.kitId,
                formInitialValues: emptyVal,
              },
            }),
          );
        }
      }
    });
  };

  return <KitForm {...props} onSubmit={onCreate} />;
};

export const KitUpdateForm = props => {
  const dispatch = useDispatch();
  const uniTableColumns = useColumns(SampleUniModTableColumns);

  const [samples, setSamples] = useState([]);

  useEffect(() => {
    fetchData(`/kits/get/${props.kit.kitId}`, "get").then(res => {
      setSamples(res.samples);
    });
  }, []);

  const onUpdate = values => {
    // Swal.fire({
    //   title: "",
    //   text: t("if upd kit"),
    //   icon: "info",
    //   confirmButtonText: t("Continue"),
    //   showCancelButton: true,
    //   cancelButtonText: t("Cancel"),

    //   customClass: {
    //     popup: "zindex",
    //     container: "zindex",
    //     htmlContainer: "zindex",
    //   },
    // }).then(answ => {
    //   if (answ.isConfirmed) {
    dispatch(updateKit({kitId: props?.kit.kitId, data: {name: values.setDescArea, skuNumber: values.kitSku}})).then(
      res => {
        if (res.meta.requestStatus === "fulfilled") {
          getInfo(t("ins updated"));
          dispatch(
            updatePageTabProps({
              pageType: props.formType,
              props: {
                formInitialValues: {
                  ...props.formInitialValues,
                  setDescArea: res.payload.name,
                  kitSku: res.payload.skuNumber,
                },
              },
            }),
          );
        }
      },
    );
    //   }
    // });
  };

  const data = useMemo(() => samplesForKitModalDataService(samples), [samples]);

  return (
    <div className="fcCol h100 form_wrapper">
      <KitForm {...props} onSubmit={onUpdate} />
      <div className="fGrow" style={{paddingBottom: "2em", overflowY: "hidden"}}>
        <ReactTable
          defaultData={data || []}
          columns={uniTableColumns}
          selectable={false}
          className={`mb scrollX overflowAuto fGrow`}
        />
      </div>
    </div>
  );
};

export const KitDublicateForm = props => {
  const dispatch = useDispatch();
  const uniTableColumns = useColumns(SampleUniModTableColumns);

  const onUpdate = values => {
    dispatch(updateKit({kitId: props?.kit.kitId, data: {name: values.setDescArea, skuNumber: values.kitSku}})).then(
      res => {
        if (res.meta.requestStatus === "fulfilled") {
          getInfo(t("ins updated"));
          dispatch(
            updatePageTabProps({
              pageType: props.formType,
              props: {
                formInitialValues: {
                  setDescArea: res.payload.name,
                  kitSku: res.payload.skuNumber,
                },
              },
            }),
          );
        }
      },
    );
  };
  const onDublicate = values => {
    dispatch(dublicateKit(props.baseKit.kitId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        dispatch(
          updateKit({kitId: resp.payload.kitId, data: {name: values.setDescArea, skuNumber: values.kitSku}}),
        ).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getSuccess(t("Sample dublicated"));
            dispatch(closeCurrentTab());
            // dispatch(
            //   updatePageTabProps({
            //     pageType: props.formType,
            //     props: {
            //       kit: {images: resp.payload.images, kitId: resp.payload.kitId, qrCodeUrl: resp.payload.qrCodeUrl},
            //       formInitialValues: {
            //         setDescArea: res.payload.name,
            //         kitSku: res.payload.skuNumber,
            //       },
            //     },
            //   }),
            // );
          }
        });
      }
    });
  };
  const data = useMemo(() => samplesForKitModalDataService(props?.baseKit?.samples), [props?.baseKit]);

  return (
    <div className="fcCol h100">
      <KitForm {...props} kit={props?.kit || null} onSubmit={props?.kit ? onUpdate : onDublicate} />
      {/* <div className="fGrow" style={{paddingBottom: "1em"}}> */}
      <ReactTable
        defaultData={data || []}
        columns={uniTableColumns}
        selectable={false}
        className={`mb scrollX overflowAuto fGrow`}
      />
      {/* </div> */}
    </div>
  );
};

export const KitViewForm = props => {
  const [initialValues, setInitial] = useState();
  const [kit, setKit] = useState();

  useEffect(() => {
    fetchData(`/kits/get/${props.kitId}`, "get").then(res => {
      setKit(res);
      setInitial({
        brandManager: res?.brandManager ? res?.brandManager?.firstName + " " + res?.brandManager?.lastName : "",
        kitSku: res?.skuNumber,
        setDescArea: res?.name,
      });
    });
  }, []);

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form>
        <KitForm {...props} kit={kit || null} />;
      </Form>
    </Formik>
  );
};
